<div *ngIf="resource" class="px-20 my-5">
  <prism-title additionalClasses="text-4xl" title="Resource"></prism-title>
  <div class="relative w-full h-64 overflow-hidden mb-8">
      <img [src]="blobImgUrl" alt="Hero Image" class="absolute inset-0 w-full h-full object-cover opacity-50">
    <div class="absolute inset-0 flex items-center justify-center">
      <div class="p-6 text-white text-center">
        <div class="text-5xl font-bold text-dark-gray mt-8">{{ resource.title }}</div>
        @if (isEditable) {
          <prism-btn btnClass="btn-white" (click)="goToResourceForm()">
            <i class="uil uil-edit text-lg"></i>
            <span class="text-lg"> Edit resource</span>
          </prism-btn>
        }
      </div>
    </div>
  </div>
  <p class="font-bold text-dark-purple text-4xl">Description</p>
  @if (isExternal) {
    <p class="mb-6">{{ resource.description }}</p>
    <prism-btn (click)="goToResource()">{{ resource.buttonLabel }}</prism-btn>
  }
  @else {
    <quill-view [content]="resource.description" theme="snow"></quill-view>
    <p class="font-bold text-dark-purple text-lg">Link</p>
    <a class="text-blue underline" href="{{ resource.videoLink }}" target="_blank">{{ extractDomain(resource.videoLink) }}</a>
  }
</div>