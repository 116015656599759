import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICounty } from '@core/interfaces/ilookup';
import { LookupService } from '@core/services/lookup.service';
import { tap } from 'rxjs';

@Component({
  selector: 'prism-select-county',
  templateUrl: './select-county.component.html',
  styleUrl: './select-county.component.scss'
})
export class SelectCountyComponent {
  private lookupService= inject(LookupService);
  @Output() county = new EventEmitter<any>();
  @Input() selectedCounty?: number | null;
  @Input() control!: FormControl | any;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() readOnly?: boolean = false;
  @Input() labelClass: string = '';
  options: any[] = [];

  constructor() {}

  ngOnInit(): void {
    this.getCounties();
  }

  getCounties(): void {
    this.lookupService.getCounties()
    .pipe(
      tap((res: ICounty[]) => this.options = res)
    )
    .subscribe();
  }

  emitCounty(county: any): void {
    this.county.emit(county);
  }
}
