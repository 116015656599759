import { Injectable, inject } from "@angular/core";
import { DataService } from "./data.service";
import { Observable } from "rxjs";
import { IAdvisoryCommittee, ICounty, ICourses, IDistricts, IFaqs, IGrades, IRoles } from "@core/interfaces/ilookup";

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  dataService = inject(DataService);

  getOrganizations(): Observable<IDistricts[]> {
    return this.dataService.get(`lookup/organizations`);
  }

  getRoles(): Observable<IRoles[]> {
    return this.dataService.get(`lookup/roles`);
  }

  getGrades(): Observable<IGrades[]> {
    return this.dataService.get(`lookup/grades`);
  }

  getCourses(): Observable<ICourses[]> {
    return this.dataService.get(`lookup/Courses`);
  }

  getFaqs(): Observable<IFaqs[]> {
    return this.dataService.get(`lookup/faqs`);
  }

  getAdvisoryCommittee(): Observable<IAdvisoryCommittee[]> {
    return this.dataService.get(`lookup/advisory-committee`);
  }

  getCounties(): Observable<ICounty[]> {
    return this.dataService.get(`lookup/counties`);
  }
}
