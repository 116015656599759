import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IDistricts } from '@core/interfaces/ilookup';
import { LookupService } from '@core/services/lookup.service';
import { pipe, tap } from 'rxjs';

@Component({
  selector: 'prism-select-organization',
  templateUrl: './select-organization.component.html',
  styleUrl: './select-organization.component.scss'
})
export class SelectOrganizationComponent implements OnInit{
  @Output() organization = new EventEmitter<any>();
  @Input() selectedOrganization?: number | null;
  @Input() control!: FormControl | any;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() readOnly?: boolean = false;
  @Input() labelClass: string = '';
  options: IDistricts[] = [];

  constructor(private lookupService: LookupService) {}

  ngOnInit(): void {
    this.getOrganizations();
  }

  getOrganizations(): void {
    this.lookupService.getOrganizations()
    .pipe(
      tap((res: IDistricts[]) => this.options = res.map((x: IDistricts) => ({ name: x.districtCountyName || x.name, id: x.id })))
    )
    .subscribe();
  }

  emitOrganization(organization: any): void {
    this.organization.emit(organization);
  }
}
