import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { UserManagementService } from '@views/features/management/services/user-management.service';
import { take } from "rxjs";

@Injectable()
export class UserValidator {

  constructor(private userService: UserManagementService) {}

  validateEmailAddress(emailAddress: string, initialValue?: any) {
    return (control: FormGroup) => {
      let value = control.controls[emailAddress].value;
      const form = control.controls[emailAddress];
      if (value != '' && value != initialValue) {
        let encodedEmail: string | null = null;
        if (value && value.includes('+')) {
          encodedEmail = value.replace(/\+/g, '%2B')
        }
        this.userService.isDuplicateEmail(encodedEmail ?? value)
          .subscribe((res: boolean) => {
            return res ? form.setErrors({ invalidEmail: true, customError: 'Email address unavailable.' }) : null
          })
      }
    }
  };
}
