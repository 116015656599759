import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from './components/btn/btn.component';
import { TitleComponent } from './components/title/title.component';
import { InputComponent } from './components/input/input.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastComponent } from './components/toast/toast.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TableComponent } from './components/table/table.component';
import { SelectComponent } from './components/dropdowns/select/select.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SelectOrganizationComponent } from './components/dropdowns/select-organization/select-organization.component';
import { SpinnerComponent } from './components/spinnner/spinner.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SelectRoleComponent } from './components/dropdowns/select-role/select-role.component';
import { SelectGradeComponent } from './components/dropdowns/select-grade/select-grade.component';
import { CardComponent } from './components/cards/card/card.component';
import { QuillModule } from 'ngx-quill'
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { CourseCardComponent } from './components/cards/course-card/course-card.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { SelectPageSizeComponent } from './components/dropdowns/select-page-size/select-page-size.component';
import { AddAriaLabelDirective } from './directives/add-aria-label.directive';
import { DateFormatPipe } from "../core/pipes/date-format.pipe";
import { SelectCountyComponent } from './components/dropdowns/select-county/select-county.component';

@NgModule({
    declarations: [
        BtnComponent,
        TitleComponent,
        InputComponent,
        ToastComponent,
        TableComponent,
        SpinnerComponent,
        SelectComponent,
        SelectOrganizationComponent,
        SelectRoleComponent,
        SelectGradeComponent,
        CardComponent,
        CourseCardComponent,
        SelectPageSizeComponent,
        AddAriaLabelDirective,
        SelectCountyComponent
    ],
    exports: [
        BtnComponent,
        TitleComponent,
        InputComponent,
        TableComponent,
        SelectComponent,
        SelectOrganizationComponent,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        NzSelectModule,
        SpinnerComponent,
        SelectRoleComponent,
        SelectGradeComponent,
        CardComponent,
        CourseCardComponent,
        NzCheckboxModule,
        AddAriaLabelDirective,
        SelectCountyComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NzSelectModule,
        NzPaginationModule,
        NzTableModule,
        NzDropDownModule,
        NzSpinModule,
        QuillModule.forRoot(),
        NzBadgeModule,
        NzCheckboxModule,
        DateFormatPipe
    ]
})
export class SharedModule { }
